import _map from 'lodash/map'
import { zonedTimeToUtc } from 'date-fns-tz'
import * as types from '../actionTypes';
import { call, put, getContext, takeLatest } from 'redux-saga/effects';
import { BusinessType, FormKeyMap, IdTypeValueMap } from '@/components/SeniorAuthFormArea/constant'
import { TimeLine } from '@/types/store'
import _ from 'lodash';
import { CountryValueMap } from '@/components/PrimaryAuthFormArea/constant';

const SENIOR_FORM_DATA_URL = 'kyc/info';

const convertFile = (record) => {
  return {
    fileId: record?.fileId,
    file: { name: record?.name },
    img: record?.url
  }
}

const genFormatRecord = (data) => {
  if (!data || !data.merchantType) return {};
  const businessType = data.merchantType + '';
  let result: any = {
    business_type: businessType,
  }

  console.log("getSeniorData",data)
  const { legalPerson, businessLicenseInfo, addressInfo, bankList, storeInfo, shareholderList, directorList} = data;
  const bankInfo = bankList[0];

  result = {
    ...result,
    [FormKeyMap.companyNationality]: storeInfo?.country,

    // UploadCompanyCert
    [FormKeyMap.proofType]: businessLicenseInfo?.certificateType,
    [FormKeyMap.certDate]: businessLicenseInfo?.businessTime,
    [FormKeyMap.certBusinessOrCompanyImg]: _map(businessLicenseInfo?.businessLicensePicList, p => convertFile(p)),

    // CompanyAddress
    [FormKeyMap.shopRegion]: addressInfo?.country,
    [FormKeyMap.shopState]: addressInfo?.state, 
    [FormKeyMap.shopCity]: addressInfo?.city,
    [FormKeyMap.shopEnCity]: addressInfo?.enCity,
    [FormKeyMap.shopDistrict]: addressInfo?.region,
    [FormKeyMap.shopEnDistrict]: addressInfo?.enRegion,
    [FormKeyMap.shopPostcode]: addressInfo?.postalCode,
    [FormKeyMap.shopAddress]: addressInfo?.address,
    [FormKeyMap.shopStreet]: addressInfo?.street,
    [FormKeyMap.shopHouseNum]: addressInfo?.houseNumberOrName,
    // OwnerVer
    [FormKeyMap.firstName]: legalPerson?.firstName || (directorList && directorList[0].firstName),
    [FormKeyMap.lastName]: legalPerson?.lastName || (directorList && directorList[0].lastName),
    [FormKeyMap.zhFirstName]: legalPerson?.zhFirstName || (directorList && directorList[0].zhFirstName),
    [FormKeyMap.zhLastName]: legalPerson?.zhLastName || (directorList && directorList[0].zhLastName),
    [FormKeyMap.idNumber]: legalPerson?.idNumber || (directorList && directorList[0].idNumber),
    [FormKeyMap.liveCountryRegion]: legalPerson?.country || (directorList && directorList[0].country),
    [FormKeyMap.liveState]: legalPerson?.state || (directorList && directorList[0].state),
    [FormKeyMap.liveCity]: legalPerson?.city || (directorList && directorList[0].city),
    [FormKeyMap.liveEnCity]: legalPerson?.enCity || (directorList && directorList[0].enCity),
    [FormKeyMap.liveDistrict]: legalPerson?.region || (directorList && directorList[0].region),
    [FormKeyMap.liveEnDistrict]: legalPerson?.enRegion || (directorList && directorList[0].enRegion),
    [FormKeyMap.livePostcode]: legalPerson?.postalCode || (directorList && directorList[0].postalCode),
    [FormKeyMap.liveAddress]: legalPerson?.address || (directorList && directorList[0].address),
    [FormKeyMap.liveStreet]: legalPerson?.street || (directorList && directorList[0].street),
    [FormKeyMap.liveHouseNum]: legalPerson?.houseNumberOrName || (directorList && directorList[0].houseNumberOrName),
    [FormKeyMap.idType]: legalPerson?.idType || (directorList && directorList[0].idType),
    [FormKeyMap.nationality]: legalPerson?.nationality || (directorList && directorList[0].nationality),
    [FormKeyMap.idExpiryDate]: legalPerson?.idExpiration|| (directorList && directorList[0].idExpiration),
    [FormKeyMap.dateOfBirth]: legalPerson?.dateOfBirth || (directorList && directorList[0].dateOfBirth),

    // UploadPassportOrIdCard
    [FormKeyMap.passportImg]: legalPerson?.idType === IdTypeValueMap.PASSPORT ? convertFile(legalPerson?.frontIdPic) : undefined,
    [FormKeyMap.uploadIdImgFront]: legalPerson?.idType !== IdTypeValueMap.PASSPORT ? convertFile(legalPerson?.frontIdPic) : undefined,
    [FormKeyMap.uploadIdImgBack]: legalPerson?.idType !== IdTypeValueMap.PASSPORT ? convertFile(legalPerson?.backIdPic) : undefined,

    // StoreInfo
    [FormKeyMap.introGoodsType]: storeInfo.services,
    [FormKeyMap.expectedMonthlySale]: storeInfo.gmv,
    [FormKeyMap.shopSaleType]: storeInfo.saleType,

    // Shareholder
    [FormKeyMap.shareholderList]: _map(shareholderList, shareholder => ({
      [FormKeyMap.firstName]: shareholder.firstName,
      [FormKeyMap.lastName]: shareholder.lastName,
      [FormKeyMap.zhFirstName]: shareholder.zhFirstName,
      [FormKeyMap.zhLastName]: shareholder.zhLastName,
      [FormKeyMap.nationality]: shareholder.nationality,
      [FormKeyMap.idType]: shareholder.idType,
      [FormKeyMap.idNumber]: shareholder.idNumber,
      [FormKeyMap.gender]: shareholder.gender,
      [FormKeyMap.idExpiryDate]: shareholder.idExpiration,
      [FormKeyMap.dateOfBirth]: shareholder.dateOfBirth,
      [FormKeyMap.liveCountryRegion]: shareholder.country,
      [FormKeyMap.liveState]: shareholder.state,
      [FormKeyMap.liveCity]: shareholder.city,
      [FormKeyMap.liveEnCity]: shareholder.enCity,
      [FormKeyMap.liveDistrict]: shareholder.region,
      [FormKeyMap.liveEnDistrict]: shareholder.enRegion,
      [FormKeyMap.livePostcode]: shareholder.postalCode,
      [FormKeyMap.liveAddress]: shareholder.address,
      [FormKeyMap.liveStreet]: shareholder?.street,
      [FormKeyMap.liveHouseNum]: shareholder?.houseNumberOrName,
      [FormKeyMap.passportImg]: convertFile(shareholder.frontIdPic),
      [FormKeyMap.uploadIdImgFront]: convertFile(shareholder.frontIdPic),
      [FormKeyMap.uploadIdImgBack]: convertFile(shareholder.backIdPic),
    })),

    [FormKeyMap.bankList]: _map(bankList, bank => ({
      [FormKeyMap.accountRegion]: bank.country,
      [FormKeyMap.accountBankCode]: bank.bankCode,
      [FormKeyMap.accountBankName]: bank.bankName,
      [FormKeyMap.accountPersonName]: bank.accountName,
      [FormKeyMap.bankBranchName]: bank.branchName,
      [FormKeyMap.bankBranchCode]: bank.branchCode,
      [FormKeyMap.bankSwiftCode]: bank.swiftCode,
      [FormKeyMap.bankAccount]: bank.accountNo,
      [FormKeyMap.bankAccountName]: bank.accountName,
      [FormKeyMap.certBankImg]: convertFile(bank.checkBillPic),
      // NowAddress: may not exist this FormKeyMap.upload6MonthBill but HK, when HK only set one bank
      // [FormKeyMap.upload6MonthBill]: convertFile(bank.checkBillPic),
      [FormKeyMap.isDefaultBankAccount]: bank.isDefault === 1 ? true : false,
      [FormKeyMap.bankAccountCurrency]: bank.currency,
      [FormKeyMap.bankAccountType]: bank.accountType.toString(),
    })),

    // BankAccount
    [FormKeyMap.accountRegion]: bankInfo?.country,
    [FormKeyMap.accountBankCode]: bankInfo?.bankCode,
    [FormKeyMap.accountBankName]: bankInfo?.bankName,
    [FormKeyMap.accountPersonName]: bankInfo?.accountName,
    [FormKeyMap.bankBranchName]: bankInfo?.branchName,
    [FormKeyMap.bankBranchCode]: bankInfo?.branchCode,
    [FormKeyMap.bankAccount]: bankInfo?.accountNo,
    [FormKeyMap.bankSwiftCode]: bankInfo?.swiftCode,
    // [FormKeyMap.upload6MonthBill]: bankInfo && convertFile(bankInfo?.checkBillPic),
    [FormKeyMap.certBankImg]: bankInfo && convertFile(bankInfo?.checkBillPic),
    [FormKeyMap.bankAccountCurrency]: bankInfo?.currency,
    [FormKeyMap.bankAccountType]: bankInfo?.accountType,
    [FormKeyMap.isDefaultBankAccount]: bankInfo?.isDefault === 1 ? true : false,

    [FormKeyMap.directorList]: _map(directorList, director => ({
      [FormKeyMap.firstName]: director.firstName,
      [FormKeyMap.lastName]: director.lastName,
      [FormKeyMap.zhFirstName]: director.zhFirstName,
      [FormKeyMap.zhLastName]: director.zhLastName,
      [FormKeyMap.nationality]: director.nationality,
      [FormKeyMap.gender]: director.gender,
      [FormKeyMap.idType]: director.idType,
      [FormKeyMap.idNumber]: director.idNumber,
      [FormKeyMap.dateOfBirth]: director.dateOfBirth,
      [FormKeyMap.passportImg]: convertFile(director.frontIdPic),
      [FormKeyMap.uploadIdImgFront]: convertFile(director.frontIdPic),
      [FormKeyMap.uploadIdImgBack]: convertFile(director.backIdPic),
      [FormKeyMap.liveCountryRegion]: director.country,
      [FormKeyMap.liveState]: director.state,
      [FormKeyMap.liveCity]: director.city,
      [FormKeyMap.liveEnCity]: director.enCity,
      [FormKeyMap.liveDistrict]: director.region,
      [FormKeyMap.liveEnDistrict]: director.enRegion,
      [FormKeyMap.livePostcode]: director.postalCode,
      [FormKeyMap.liveAddress]: director.address,
      [FormKeyMap.liveStreet]: director.street,
      [FormKeyMap.liveHouseNum]: director.houseNumberOrName,
    })),
  }

  result[FormKeyMap.defaultDirector] = businessType === BusinessType.limitedCompany
    && result[FormKeyMap.directorList] && _.cloneDeep(directorList[0]);

  if (!result[FormKeyMap.uploadIdImgFront]?.fileId && !_.isEmpty(directorList)) {
    result[FormKeyMap.passportImg] = directorList[0].idType === IdTypeValueMap.PASSPORT
      ? convertFile(directorList[0].frontIdPic) : undefined;
    result[FormKeyMap.uploadIdImgFront] = businessType === BusinessType.limitedCompany
      ? convertFile(directorList[0].frontIdPic) : undefined;
  }
  if (!result[FormKeyMap.uploadIdImgBack]?.fileId && !_.isEmpty(directorList)) {
    result[FormKeyMap.uploadIdImgBack] = businessType === BusinessType.limitedCompany
      ? convertFile(directorList[0].backIdPic) : undefined;
  }
    // console.log(data?.bussinessAddressInfo?.address)
    result[FormKeyMap.businessCountryRegion]=data?.bussinessAddressInfo?.country
    result[FormKeyMap.businessState]=data?.bussinessAddressInfo?.state
    result[FormKeyMap.businessCity]=data?.bussinessAddressInfo?.city
    result[FormKeyMap.businessEnCity]=data?.bussinessAddressInfo?.enCity
    result[FormKeyMap.businessDistrict]=data?.bussinessAddressInfo?.region
    result[FormKeyMap.businessEnDistrict]=data?.bussinessAddressInfo?.enRegion
    result[FormKeyMap.businessPostcode]=data?.bussinessAddressInfo?.postalCode
    result[FormKeyMap.businessAddress]=data?.bussinessAddressInfo?.address
    result[FormKeyMap.businessStreet]=data?.bussinessAddressInfo?.street
    result[FormKeyMap.businessHouseNum]=data?.bussinessAddressInfo?.houseNumberOrName
  

  result[FormKeyMap.directorList] = [BusinessType.limitedCompany, BusinessType.enterprise].includes(businessType as any)
    && result[FormKeyMap.directorList]
    && _.drop(_.cloneDeep(result[FormKeyMap.directorList]));

  console.log("getSeniorFormatRecord",result)
  return result;
}

function* getSeniorFormDatas({ payload: { slPaymentMerchantId } }: ReturnType<typeof types.getSeniorFormData>) {
  const api = yield getContext('api');
  const query = slPaymentMerchantId ? `?sl_payment_merchant_id=${slPaymentMerchantId}` : '';
  const result = yield call(api.get, SENIOR_FORM_DATA_URL + query);
  console.log("SeniorResult",result)
  const resultData = genFormatRecord(result.data);
  yield put({ type: types.setSeniorFormData, payload: resultData });
  if (!slPaymentMerchantId) {
    yield put({ type: types.setSeniorTimeline, payload: genTimeline(result.data) });
  }
}

const genTimeline = (data): TimeLine[] => {
  // console.log(data)
  return [
    {
      eventType: 'basic_create',
      by: data?.contactInfo.email,
      create: zonedTimeToUtc(data.createTime, 'GMT').valueOf(),
    },
  ];
};

export default function* watchSeniorAuthRecord() {
  yield takeLatest(types.getSeniorFormData, getSeniorFormDatas);
}
