import { takeEvery, put, getContext, call } from 'redux-saga/effects';
import * as types from './actionTypes';
import { supportedLangs } from '@/i18n'
import { CountryValueMap } from '@/components/PrimaryAuthFormArea/constant';
import { getAdminV2 } from '../../utils/common';
import countriesMapList from '@/assets/locales/countries';
const FALLBACK_LANG = 'en';

function* getRegion({ payload }: ReturnType<typeof types.getRegion>) {
  const api = yield getContext('api');
  // const result = yield call(api.get, 'countries');


  const data = countriesMapList.map((country) => {
    const labels = {};
    supportedLangs.forEach(lang => {
      labels[lang] = country[lang] || country[FALLBACK_LANG];
    });
    return {
      key: country.key,
      value: country.key.toUpperCase(),
      labels
    };
  });
  yield put({ type: types.setRegion, payload: data });
}

function* getBank({ payload }: ReturnType<typeof types.getBank>) {
  const api = yield getContext('api');
  let data: any[] = [];
  try {
    let exist = {};
    let result = yield call(api.get, 'banks?country=' + payload);

    result.data.forEach((r,idx) => {
      let key = ''
      if(payload === CountryValueMap.cn){
        key = `${r.name} ${r.code}`
      }
      else if(payload === CountryValueMap.tw && r?.showCodeLength===3){
        key = `${r.code.substr(0,3)} ${r.name}`
      }
      else{
        key = `${r.code} ${r.name}`
      }
      if (!exist[key]) {
        exist[key] = r;
        data.push({
          key: key,
          value: r.code,
          label: key,
          code: r.code,
          name: r.name,
        });
      }
    });
  } catch {}

  yield put({ type: types.setBank, payload: data });
}

function getBranchNameWithoutBankName(branchName: string) {
  const matchCompanyStringIndex = branchName.indexOf('有限公司');
  if (matchCompanyStringIndex > -1) {
    return branchName.substring(matchCompanyStringIndex + 4);
  }
  return branchName.substring(branchName.indexOf('银行') + 2);
}

function* getBankBranchesOptions({ payload: { payload, resolve, reject } }) {
  const api = yield getContext('api');
  const data: any[] = [];
  try {
    // console.log(payload.region)
    const result = yield call(api.get, `bank/branches?region=${payload.region}&bankCode=${payload.bankCode}`);
    result.data.forEach(branch => {
      let label = ''
      if(payload.region === CountryValueMap.cn){
        label=`${getBranchNameWithoutBankName(branch.bank_branch_name)} ${branch.bank_branch_code}`
      }
      // else if(payload.region === CountryValueMap.tw){
      //   label = `${branch.bank_branch_code.substr(0,3)} ${branch.bank_branch_name}`
      // }
      else{
        label = `${branch.bank_branch_code} ${branch.bank_branch_name}`
      }
      data.push({
        key: `${branch.bank_branch_code} ${branch.bank_branch_name}`,
        value: branch.bank_branch_code,
        label,
        code: branch.bank_branch_code,
        name: branch.bank_branch_name,
      });
    });
  } catch(e) {
    console.error(e);
  }

  if (resolve) {
    resolve(data);
  }
}

export function* getLocations({ payload: { payload, resolve, reject } }) {
  const api = yield getContext('api');
  let result: any = {};
  let data = [];
  try {
    result = yield call(api.get, 'locations?country=' + payload);
    data = result.data.map((stateOrCity) => {
      const stateOrCityLabels = {};
      supportedLangs.forEach(lang => {
        stateOrCityLabels[lang] = stateOrCity[lang] || stateOrCity[FALLBACK_LANG];
      });
      return {
        key: stateOrCity['en'],
        value: stateOrCity['en'],
        labels: stateOrCityLabels,
        children: stateOrCity.children?.map((cityOrDistrict) => {
          const cityOrDistrictLabels = {};
          supportedLangs.forEach(lang => {
            cityOrDistrictLabels[lang] = cityOrDistrict[lang] || cityOrDistrict[FALLBACK_LANG];
          });
          return {
            key: cityOrDistrict['en'],
            value: cityOrDistrict['en'],
            labels: cityOrDistrictLabels,
            children: cityOrDistrict.children?.map((district) => {
              const districtLabels = {};
              supportedLangs.forEach(lang => {
                districtLabels[lang] = district[lang] || district[FALLBACK_LANG];
              });
              return {
                key: district['en'],
                value: district['en'],
                labels: districtLabels,
              }
            }),
          };
        }),
      };
    });
  } catch(e) {
    console.error(e);
  }

  if (resolve) {
    resolve(data);
  }
  // yield put({ type: types.setCity, payload: data });
}

function* getBankOptions({ payload: { payload, resolve, reject } }) {
  const api = yield getContext('api');
  let data: any[] = [];
  try {
    let exist = {};
    let result = yield call(api.get, 'banks?country=' + payload);
    result.data.forEach((r) => {
      let key = ''
      if(payload === CountryValueMap.cn){
        key= `${r.name} ${r.code}`
      }
      else{
        key= `${r.code} ${r.name}`
      }
      // console.log("BankOptions",key)
      // const key = payload === CountryValueMap.cn
      //   ? `${r.name} ${r.code}`
      //   : `${r.code} ${r.name}`;
      if (!exist[key]) {
        exist[key] = r;
        data.push({
          key: key,
          value: r.code,
          label: key,
          code: r.code,
          name: r.name,
        });
      }
    });
  } catch (e) {
    console.error(e);
  }

  if (resolve) {
    resolve(data);
  }
  // yield put({ type: types.setBank, payload: data });
}

function* getShopInfo({ payload: { payload, resolve, reject }}: ReturnType<typeof types.getShopInfo>) {
  const api = yield getContext('api');
  let result: any = {};
  try {
    const userRoute = getAdminV2() ? 'user' : 'userinfo';
    result = yield call(api.get, userRoute);
  } catch(err) {
    console.log('get user error', err);
  }
  let data = result.data;

  yield put({ type: types.setShopInfo, payload: data });
  
  resolve && resolve(data);
}

function* uploadFile({ payload: { payload, resolve, reject } }: ReturnType<typeof types.uploadFile>) {
  const api = yield getContext('api');

  const formData = new FormData();
  formData.append('file', payload.file);
  // formData.append('documentType', payload.documentType);
  try {
    const result = yield call(api.post, `kyc/attachments?documentType=${payload.documentType}`, formData);
    resolve(result.data);
  } catch (e) {
    console.log(e, '上传照片错误');
    reject(e);
  }
}

export default function* basicSagas() {
  yield takeEvery(types.getRegion, getRegion);
  yield takeEvery(types.getBank, getBank);
  yield takeEvery(types.getBankBranches, getBankBranchesOptions);
  yield takeEvery(types.getShopInfo, getShopInfo);
  yield takeEvery(types.getLocations, getLocations);
  yield takeEvery(types.uploadFile, uploadFile);
  yield takeEvery(types.getBankOptions, getBankOptions);
}
